
.app {
  min-height: 90vh;
}

.post {
  $this: &;

  .text-muted, a {
    color: #6c757d;
  }

  &--pinned {
    position: relative;

    &:before {
      position: absolute;
      top: -12px;
      right: 10px;
      text-transform: uppercase;
      content: '\f08d';
      background: rgb(67, 124, 255);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
      font-family: "font awesome 5 free";
      font-weight: 900;
      border-radius: 2px;
      font-size: 16px;
      width: 30px;
      text-align: center;
      line-height: 18px;
      padding: 5px;
    }
  }

  &--hero, &--pinned {
    background: rgb(67, 124, 255);
    color: white;
    padding: 1rem;
    border-radius: 5px;

    .text-muted, a {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    #{$this}__media {
      margin: 1rem 0;
    }
  }
}

.social-links {
  list-style: none;
  padding-left: 0;

  &__item {
    display: inline;
  }

  &__link {
    font-size: 28px;
    line-height: 48px;
    width: 48px;
    height: 48px;
    padding: 0px 8px;
    margin: 2px;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    color: white;

    &:hover {
      opacity: 0.8;
      color: white;
    }

    img {
      max-height: 32px;
      margin-top: -8px;
    }
  }
}

.alt-list {
  list-style: none;
  padding-left: 0;

  &__item {
    display: flex;

    & > span {
      margin-top: 5px;
    }

    label {
      display: inline-block;
      padding: 0 0 2px 8px;
    }
  }
}

.partners {
  &__title {
    background-color: #1c1c1c;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1.6em;
    font-weight: bold;
    letter-spacing: 0px;
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

.sponsors {
  background-color: white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  &__item {
    min-width: 120px;
    width: 150px;
    max-width: 10%;
  }

  img {
    display: block;
    max-width: 100%;
    padding: 1rem;
  }
}
